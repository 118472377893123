// ** Default import

import React, { useContext } from 'react';

// ** Utils

import { useTranslation } from 'react-i18next';
import congesService from '@data/congesService';
import ToastCustom from '@components/UI/ToastCustom';
import InputCustom from '@components/UI/InputCustom';
import { Formik } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import UserContext from '@context/UserContext';

import { getFullname } from '@utility/Utils';

const Account = () => {
  const { t } = useTranslation();

  const { currentUser, setCurrentUser } = useContext(UserContext);
  // Getting user data from local storage

  const daysList = [
    {
      id: 1,
      day_name: 'monday',
      value: true,
    },
    {
      id: 2,
      day_name: 'tuesday',
      value: true,
    },
    {
      id: 3,
      day_name: 'wednesday',
      value: true,
    },
    {
      id: 4,
      day_name: 'thursday',
      value: true,
    },
    {
      id: 5,
      day_name: 'friday',
      value: true,
    },
    {
      id: 6,
      day_name: 'saturday',
      value: true,
    },
    {
      id: 7,
      day_name: 'sunday',
      value: true,
    },
  ];

  // Update notifications
  const handleNotifications = (e) => {
    const { checked } = e.target.checked;
    congesService
      .put(`/v1/users/${currentUser.id}`, {
        can_receive_mails: checked,
      })
      .then((res) => {
        setCurrentUser((prevState) => ({
          ...prevState,
          can_receive_mails: checked,
        }));
        ToastCustom.validated('successUpdate');
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error('errorOnSave');
      });
  };

  // Update open days
  const handleDays = (event, props, index) => {
    const { checked } = event.target;
    const days = props.values.days;

    days[index].value = checked;

    const daysToSend = {};
    const daysToSave = [];

    days
      .filter((day) => day.value === true)
      .forEach((day) => {
        daysToSave.push(day.id);
      });
    days
      .filter((day) => day.value === true)
      .forEach((day) => {
        return (daysToSend[day.day_name] = Number(day.value));
      });

    congesService
      .put(`/v1/users/current/open-days`, daysToSend)
      .then((res) => {
        ToastCustom.validated('successUpdate');
        setCurrentUser((prevState) => ({
          ...prevState,
          days: daysToSave,
        }));
      })
      .catch((error) => {
        console.error(error);
        ToastCustom.error('errorOnsave');
      });

    props.setFieldValue('days', days);
  };

  const handleAbsenteesMails = (e, props) => {
    const reverseValue = !props.values.can_receive_absentees_reminder_mails;
    congesService
      .put(`/v1/users/${currentUser.id}`, {
        can_receive_absentees_reminder_mails: reverseValue,
      })
      .then((res) => {
        props.setFieldValue(
          'can_receive_absentees_reminder_mails',
          reverseValue
        );
        ToastCustom.validated('successUpdate');
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error('errorOnSave');
      });
  };

  const isManager =
    currentUser?.profile?.label === 'ADMINISTRATEURMANAGER' ||
    currentUser?.profile?.label === 'STANDARDMANAGER';

  return (
    <>
      <h1 className='text-2xl mb-10'>{t('myAccount')}</h1>
      <Formik
        initialValues={{
          can_receive_absentees_reminder_mails:
            currentUser.can_receive_absentees_reminder_mails,
          can_receive_mails: currentUser.can_receive_mails,
          days: daysList.map((day) => {
            return {
              ...day,
              value: currentUser.client.is_allowed_to_modify_open_days
                ? currentUser.days.includes(day.id)
                : currentUser.client_days.includes(day.id),
            };
          }),
          managers: currentUser.manager,
        }}
      >
        {(props) => (
          <div className='bg-white rounded-lg p-7'>
            <div className='text-primary2-500 w-full mb-8'>
              <div className='flex items-center justify-center gap-x-4 mb-7 text-red-600 relative'>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className='text-secondarylight absolute left-0 mt-[6px] cursor-pointer'
                  onClick={() => navigate(-1)}
                />
                <h2>{getFullname(currentUser)}</h2>
              </div>
              <div className='mb-2 italic'>
                <p>{currentUser.email}</p>
              </div>
              {isManager && (
                <div className='flex items-center gap-x-2'>
                  <span>{t('canReceiveAbsenteesMail')} :</span>
                  <InputCustom
                    name='can_receive_absentees_reminder_mails'
                    id='can_receive_absentees_reminder_mails'
                    checked={props.values.can_receive_absentees_reminder_mails}
                    onChange={(e) => handleAbsenteesMails(e, props)}
                    type='switch'
                    withoutLabel={true}
                  />
                </div>
              )}
              <div className='flex items-center gap-x-2 my-2'>
                <span>{t('notifMail')} :</span>
                <InputCustom
                  name='can_receive_mails'
                  id='can_receive_mails'
                  checked={props.values.can_receive_mails}
                  onChange={(e) => handleNotifications(e, props)}
                  type='switch'
                  withoutLabel={true}
                />
              </div>
            </div>

            <div className='mb-8'>
              <h2 className='text-primary1 text-lg font-semibold my-5'>
                {t('manageValid')} {' :'}
              </h2>
              {props.values.validators?.length > 0 ? (
                props.values.validators.map((elem, index) => (
                  <ul key={index} className='flex gap-x-4'>
                    <li className='w-52'>{getFullname(elem)}</li>
                    <li>
                      {t('level')} : {elem.level}
                    </li>
                  </ul>
                ))
              ) : (
                <h3 className='flex items-center'>{t('noManagers')}</h3>
              )}
            </div>

            <h2 className='text-primary1 text-lg font-semibold my-5'>
              {t('workingDay') + ' :'}
            </h2>
            <div className='flex flex-wrap xl:flex-nowrap gap-y-3'>
              {props.values.days.map((day, index) => (
                <div
                  key={index}
                  className='text-primary2-500 w-1/3 xl:w-content flex items-center gap-2'
                >
                  <InputCustom
                    type='checkbox'
                    id={'day'}
                    disabled={
                      !currentUser.client.is_allowed_to_modify_open_days
                    }
                    name={props.values.days[index].id}
                    checked={props.values.days[index].value}
                    onChange={(e) => handleDays(e, props, index)}
                    label={t(day.day_name)}
                    labelClass='text-normal'
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Account;
